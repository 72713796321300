.nav-container.active {
    top: 0;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    height: 100vh;
    overflow: auto
  }
  
  .nav-container.active .nav-items {
    height: auto;
    overflow: auto;
  }
  
  .navigation:hover .mj-logo.white-logo {
    opacity: 0 !important
  }
  
  .navigation:hover .mj-logo.blue-logo {
    opacity: 1 !important
  }
  
  .navigation-wrapper:not(.header-overlay) .white-logo {
    opacity: 0
  }
  
  .navigation-wrapper:not(.header-overlay) .blue-logo {
    opacity: 1
  }
  
  .nav-dropdown, .nav-link, .nav-link.nav-logo, .nav-link.w--current, .dropdown-link.w-dropdown-link, .product-dropdown_container {
    border-color:rgba(221,221,221,0.4)
  }
  
  .nav-items {
    transition: height .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: height .2s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .mobile-btn__line {
    opacity: 1;
    transition: transform .2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: transform .2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .nav-container.active .mobile-btn__line:last-child {
    transform: rotate(-45deg) translate(6.5px, -6.5px)
  }
  
  .nav-container.active .mobile-btn__line:first-child {
    transform: rotate(45deg) translate(6.5px, 6.5px)
  }
  
  .nav-container.active .mobile-btn__line:nth-child(2) {
    opacity: 0
  }
  
  .navigation-wrapper.header-overlay .nav-title {
    color: #fff
  }
  
  .navigation, .dropdown-toggle, .product-dropdown-toggle, .dropdown-link {
    transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .dropdown-toggle, .nav-link {
    color: inherit;
    transition: color .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: color .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .svg-product-icon {
    fill: #aaa;
    transition: fill .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: fill .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .navigation:hover .nav-title {
    color: #111 !important
  }
  
  .product-dropdown:hover .svg-product-icon {
    fill: #debc8b
  }
  .product-dropdown:hover .product-dropdown-label {
    color:#111;
  }
  
  .nav-dropdown .nav-title::before {
    content: "\e603";
    position: absolute;
    right: -20px;
    font-family: 'webflow-icons'
  }
  .nav-dropdown.nav-product .nav-title::before {
    content:"+";
    font-family:"Neue Haas Grotesk Display Pro 65 Md";
    font-size: 1.375em;
    font-weight:700;
  }
  
  .nav-dropdown:hover .dropdown-toggle {
    background: #fff
  }
  
  .nav-dropdown:hover .nav-title::before {
    color: #009677
  }
  
  .product-dropdown.last .dropdown-secondary {
    right: 0
  }
  
  .product-items_container {
    white-space: nowrap
  }
  
  .product-list__item.secondary::before {
    content: '';
    position: absolute;
    left: 3px;
    background: #00635b;
    width: .375rem;
    height: .375rem;
    top: .5rem;
  }
  
  .product-list__link:hover {
    color: #00635b
  }
  
  .product-list__link .link-text {
    color: inherit
  }
  
  .product-list__link:hover svg {
    fill: #00635b
  }
  
  .nav-btn-sm_search:hover .svg-search {
    transform: scale(1.125)
  }
  
  .svg-search {
    fill: #111;
    transition: transform .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: transform .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .nav-btn-sm svg {
    width: 2.75em;
    height: auto
  }
  
  .nav-btn-sm_cart svg {
    fill: #fff
  }
  
  .nav-btn-sm_cart>.nav-btn-txt {
    color: #fff
  }
  
  .shopify-buy-frame.shopify-buy-frame--toggle {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0
  }
  
  .shopify-buy__cart-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 10px
  }
  
  #cart-toggle .shopify-buy__cart-toggle__count {
    position: absolute;
    right: 1rem;
    top: .75rem;
    color: #111;
    text-align: center;
    width: 1.5rem;
    font-size:initial;
    line-height: 1.625rem;
    letter-spacing: 0;
    z-index: 1
  }
  
  .shopify-buy__cart-toggle__count::after {
    content: '';
    display: block;
    background: #debc8b;
    position: absolute;
    top: .0625rem;
    right: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    z-index: -1
  }
  
  .null {
    pointer-events: none
  }
  
  .nav-transparent {
    pointer-events: none
  }
  
  
  
  

















  
  
  
  /*---1---*/
  * {
    box-sizing: border-box
  }
  
  body {
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif
  }
  
  .features__item a {
    color: #fff
  }
  
  .features__item a:hover, p a:hover {
    color: #00635b
  }
  
  .rich-text p {
    margin-top: 0;
    margin-bottom: 1.25rem;
    opacity: 1;
    font-size: 1.25rem;
    line-height: 1.5
  }
  
  body sup {
    top: -.25em;
  }
  
  html {
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box
  }
  
  @media (max-width: 1000px) {
    html {
      font-size: calc(12px + 0.4vw) !important
    }
  }
  
  .heading-jumbo.heading-jumbo {
    font-size: calc(2.5rem + 2.25vw);
    line-height: calc(2.75rem + 2.25vw)
  }
  
  h1.heading-1.heading-1 {
    font-size: calc(2rem + 1vw);
    line-height: calc(2.5rem + 1vw)
  }
  
  h2, .heading-2, h2.heading-2.heading-2 {
    font-size: calc(1.75rem + 1.25vw);
    line-height: calc(2.25rem + 1.25vw)
  }
  
  h3, .heading-3.heading-3.heading-3.heading-3 {
    font-size: calc(1.25rem + 1.5vw);
    line-height: calc(1.75rem + 1.5vw);
    height: auto;
  }
  
  .categories__item h4 {
    margin-bottom: .125rem;
    font-weight: 500
  }
  
  .categories__item h4, h4.heading-4.heading-4 {
    font-size: calc(1.25rem + .75vw);
    line-height: calc(1.75rem + .75vw)
  }
  
  h6.heading-6.heading-6 {
    font-size: calc(1.125rem + 0.2vw);
    line-height: calc(1.625rem + 0.2vw)
  }
  
  svg {
    height: 100%;
    width: 100%
  }
  
  strong {
    font-weight: 600
  }
  
  .center {
    text-align: center
  }
  
  .white {
    color: #fff;
    fill: #fff
  }
  
  .blue {
    color: #06c;
    fill: #06c
  }
  
  .red {
    color: #c03;
    fill: #c03
  }
  .green {
    color: #00635b;
    fill: #00635b
  }
  
  .gray {
    color: #676767;
    fill: #676767
  }
  
  .bg--blue .w-richtext a {
    color: #fff
  }
  
  .bg--blue .w-richtext a:hover {
    color: #00635b
  }
  
  .svg-lang svg {
    fill: #fff;
    height: 100%;
    width: 100%
  }
  
  .svg-sm-icon svg {
    fill: #fff
  }
  
  .button+.button {
    margin-top: 20px
  }
  
  .button.ghost {
    background: none;
    border: 2px solid #00635b;
    color: #00635b;
    transition: border-color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: border-color .3s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .button.ghost .line-inlay-right {
    background: #00635b
  }
  
  .button.ghost .line-inlay-left {
    color: #fff
  }
  .button-highlight-inlay {
    transform-style: preserve-3d
  }
  .button.ghost .button-highlight-inlay {
    background: #00635b
  }
  
  .button.ghost.red {
    border-color: #c03;
    color: #c03
  }
  
  .button.ghost.red .line-inlay-left, .button.ghost.red .line-inlay-right {
    background: #c03
  }
  
  .button.ghost.red .button-highlight-inlay {
    background: #c03
  }
  
  .button.ghost.white {
    border-color: #fff;
    color: #fff
  }
  
  .button.ghost.white .line-inlay-left, .button.ghost.white .line-inlay-right {
    background: #fff
  }
  
  .button.ghost.white .button-highlight-inlay {
    background: #009677
  }
  
  .ghost.white:hover {
    border-color: #009677
  }
  
  .button.ghost.white.red .button-highlight-inlay {
    background: #c03
  }
  
  .ghost.white.red:hover {
    border-color: #c03
  }
  
  .line-inlay-left, .line-inlay-right, .button-highlight {
    transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), background .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), background .3s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .button-title {
    transition: transform .3s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: transform .3s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate3d(0,0,0)
  }
  
  .button:hover .line-inlay-left, .button:hover .button-highlight {
    width: 100%
  }
  
  .button:hover .button-title {
    transform: translate3d(5.5em,0,0)
  }
  
  .button:hover .line-inlay-right {
    width: 0
  }
  
  .ghost.button:hover .button-title,.button.gold:hover .button-title {
    color: #fff
  }
  
  .ghost.button:hover .line-inlay-left, .ghost.button:hover .line-inlay-right {
    background: #fff
  }
  
  .line-inlay-left::after, .line-inlay-right::after, .line-inlay::after {
    content: '';
    height: 6px;
    width: 6px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: inherit;
    position: absolute;
    top: -3px;
    z-index: 10;
    transition: transform .3s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .line-inlay-right::after, .line-inlay::after {
    right: 0;
    transform: rotateX(0deg) rotateZ(45deg)
  }
  
  .line-inlay-left::after {
    left: 0;
    transform: rotateX(90deg) rotateZ(45deg)
  }
  
  .button:hover .line-inlay-left::after {
    transform: rotateX(0deg) rotateZ(45deg) translate3d(34px, -34px, 0)
  }
  
  .button:hover .line-inlay-right::after {
    transform: rotateX(90deg) rotateZ(45deg)
  }
  
  .svg-sm-icon {
    opacity: .8;
    transition: opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .sm-icon-wrapper:hover .svg-sm-icon {
    opacity: 1
  }
  
  .sm-icon-wrapper:first-child {
    margin-left: 0
  }
  
  .sm-icon-wrapper:last-child {
    margin-right: 0
  }
  
  .sm-icon-wrapper::before, .sm-icon-wrapper::after {
    content: '';
    border-radius: 50%;
    position: absolute;
    display: block;
    transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .sm-icon-wrapper::before {
    height: 3.5rem;
    width: 3.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2)
  }
  
  .sm-icon-wrapper::after {
    height: 2.75rem;
    width: 2.75rem;
    border: 2px solid rgba(255, 255, 255, 0.5)
  }
  
  .sm-icon-wrapper:hover:before {
    width: 2.75rem;
    height: 2.75rem
  }
  
  .sm-icon-wrapper:hover:after {
    width: 3.5rem;
    height: 3.5rem
  }
  
  a.sm-icon[href=''] {
    display: none
  }
  
  .section-text.white, .hero__content.white {
    text-shadow: 0 0 .5em rgba(0, 0, 0, 0.3)
  }
  
  .hero__content.white .paragraph.text, .hero__content.white .heading-5, .section-text.white .heading-5 {
    color: #fff
  }
  
  .hero__content.white .highlight-inlay, .hero__content.white .line-inlay, .section-text.white .highlight-inlay, .section-text.white .line-inlay {
    background: #fff
  }
  
  .flex-container.awards-wrap img {
    max-height: 5rem;
  }
  .awards-wrap.w-richtext figure img {
    height: 100%;
    width: auto;
    max-height: 5rem  
  }
  .awards-wrap figure div {
    max-height: 5rem;
    height: 20vw;
  }
  .awards-wrap .w-richtext-align-floatleft {
    margin: 0 1.5rem 0 0;
    padding: 0;
  }
  .award-highlight .w-richtext-align-center.w-richtext-figure-type-image {
    display: block;
  }
  .award-highlight .w-richtext-align-center.w-richtext-figure-type-image > div {
    width: 100%;
  }
  
  .split-section-image img {
    flex-shrink: 1;
    min-width: 100%;
    min-height: 100%;
    height: auto
  }
  
  a.manual-download[href='#'] {
    display: none
  }
  
  .manual-download:first-child {
    padding: 0;
    margin: 0;
    border: none
  }
  
  .w-dyn-item:last-child .manual-row {
    border: none
  }
  
  .footer-contact-item svg {
    fill: #fff;
    width: 1.5rem;
    height: auto
  }
  
  .w-richtext ul, .list {
    list-style: none
  }
  
  .w-richtext ul li, .w-richtext ol li {
    position: relative;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-align: left
  }
  
  .w-richtext ul li::before, .list__item::before {
    content: '';
    height: 8px;
    width: 8px;
    background: #00635b;
    display: block;
    position: absolute;
    left: -1.5rem;
    top: .625em
  }
  .white.list__item::before, .white.w-richtext ul li::before {
    background: #debc8b;
  } 
  
  .w-richtext.paragraph-small ul li, .w-richtext.paragraph-small ol li {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: .5rem
  }
  
  .w-richtext.paragraph-small ul li {
    margin-bottom: .25rem
  }
  
  .w-richtext.paragraph-small ul li::before {
    top: .5em
  }
  
  .projects .w-dyn-item:first-child {
    grid-row: span 2;
    -ms-grid-row-span: 2
  }
  
  .projects .w-dyn-item:nth-child(3), .projects .w-dyn-item:nth-child(5) {
    grid-row: span 2;
    grid-column: span 2;
    -ms-grid-row-span: 2;
    -ms-grid-column-span: 2
  }
  
  .projects .w-dyn-item:nth-child(7n) {
    grid-column: span 2;
    -ms-grid-column-span: 2
  }
  
  .projects .w-dyn-item:nth-child(3n+5) {
    grid-row: span 2;
    -ms-grid-row-span: 2
  }
  
  .projects .w-dyn-item:nth-child(5n+8) {
    grid-column: span 2;
    -ms-grid-column-span: 2
  }
  
  .projects .w-dyn-item:nth-child(17n) {
    grid-column: span 2;
    -ms-grid-column-span: 2
  }

  .project-item:hover:after {
      background: rgba(0,0,0,0.5);
  }
  .project-item::after {
      content: '';
      background: transparent;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition: background-color 0.2s ease-in-out;
  }
  .project-item__img {
    object-fit: cover
  }
  
  .project-item__content::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: -1
  }
  .project-item:hover:after {
    background: rgba(0,0,0,0.5);
  }
  .project-item::after {
    content: '';
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: background-color .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: background-color .2s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  .search-result-item a:empty ~ * {
    display: none;
  }
  .product-navigation__menu-link.w--current .heading-5, .product-navigation__menu-link:hover .heading-5 {
    color: #777
  }
  
  .lightbox-link.w-lightbox svg {
    fill: #fff
  }
  
  .lightbox-link.w-lightbox::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
    transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .lightbox-link.w-lightbox:hover:after {
    background: rgba(0, 0, 0, 0.5)
  }
  
  .da-restock-notification[disabled] {
    display: block !important;
  }
  #dd-restock-notification-container .da-restock-notification form {
    padding:1rem 1.25rem;
    margin-top:1.25rem
  }
  .mj-input {
      height: 3rem;
      width: calc(100% - 10rem);
      padding: .75rem 1.25rem;
      border: 1px solid #ddd;
  }
  .resource-row svg {
    height: 100%;
    width: 100%;
    fill: #00635b
  }
  
  .history-modal::before {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    top: -20px;
    left: 20px;
    border-width: 0 20px 20px;
    border-color: transparent transparent #f7f7f7;
    border-style: solid
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  













  
  
  
  /*---2---*/
  
  
  
  .accessories-row__item-promo:before {
    content: '';
    top: 0;
    left: 0;
    border-width: 1.75rem;
    border-style: solid;
    border-top-color: #00635b;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #00635b;
    position: absolute;
    z-index: -1;
  }
  .product_buy .shopify-buy__product .promo__btn .shopify-buy__btn {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    max-height: none;
    border: .25rem solid #009677;
    padding:1rem 1.5rem
  }
  .product_buy .shopify-buy__product .promo__btn .shopify-buy__btn::after {
    content:none
  }
  div[id*=product-table].promo .shopify-buy__product {
    margin-bottom: 0;
  }
  #promo__price .shopify-buy__product {
    margin: 1rem 0;
  }
  #promo__price .shopify-buy__product__price {
    margin-bottom: 20px;
  }
  #promo__price .shopify-buy__product__actual-price {
    color: #00635b;
    font-weight: 500;
  }
  #promo__price .shopify-buy__product__actual-price::after {
    content: '*';
  }
  .stamped-badge-starrating .stamped-fa {
    color: #00635b;
  }
  .stamped-badge-starrating {
    margin: 0 0.375rem 0 0 !important
  }
  .stamped-badge-caption {
    font-family: "Rubik";
    text-transform: uppercase;
    font-weight:500;
    letter-spacing: 1px;
    font-size: .875rem;
    white-space: nowrap;
  }
  
  .shopify-buy__product .shopify-stars .rating-count {
    position: relative;
    top: -.125rem;
    display: inline-block;
    font-size: 1rem;
    color: #111;
    margin-left: .5rem
  }
  
  svg.w-pagination-previous-icon, svg.w-pagination-next-icon {
    height: auto;
    width: auto
  }
  
  .accordian-text__inner > p, .accordian-text__inner > div {
    margin: .5rem 0 0;
  }
  
  div[id*="product-table"] .shopify-buy__product {
    padding: 0
  }
  
  div[id*="product-table"] .shopify-buy__product .shopify-buy__btn-wrapper {
    margin: 1rem 0 0
  }
  
  div.product-sub-nav .shopify-buy__product .shopify-buy__btn-wrapper {
    margin-top: 0;
  }
  .shopify-buy__product .shopify__table-button {
    display: flex;
    align-items: center;
    padding: .75rem 1.5rem;
    width: auto
  }
  
  .svg-atc {
    height: 2rem;
    width: 2rem;
    fill: #fff;
    display: inline-block;
    margin-left: 1.5rem
  }
  
  .stamped-rating-holder a {
    display: initial
  }
  
  .specs__row .shopify-buy__product__price {
    color: #00635b;
    margin-bottom: 1rem
  }
  
  
  .w-slider-nav.w-num > .w-slider-dot {
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: .125;
      color: #777;
      border: 2px solid #aaa;
      padding: 16px 12px;
      margin-right: 8px;
      background: transparent;
      border-radius: 50%;
  }
  .w-slider-nav.w-num .w-slider-dot.w-active {
      background: #00635b;
      color: #fff;
      border: 2px solid #00635b;
      margin-top: 1px;
  }
  
  
  .background-video::before, .hero.hero-overlay.dark::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:linear-gradient(to right,rgba(0,0,0,0.3),transparent);
    display: block
  }
  .background-video::before {
    background: linear-gradient(to right, transparent, rgba(0,0,0,.3), rgba(0,0,0,.4),rgba(0,0,0,.3),transparent)
  }
  
  .hero-overlay::after, .hero-overlay.product::after {
    content: '';
    position: absolute;
    height: 11rem;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent)
  }
  
  
  .highlight .shopify-buy__btn[disabled] {
    border:none
  }
  .hero__blade-inlay.push-box-inlay.blade {
    display: block;
    z-index: 2
  }
  .video-wrapper::before, .w-video::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ddd;
    z-index: -1
  }
  
  .video-wrapper::before {
    width: calc(100% - 20px)
  }
  
  .yt-embed {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0
  }
  
  .yt-embed iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute
  }
  
  input#stamped-button-submit:hover {
    background: #009677
  }
  
  input#stamped-button-submit {
    background: #00635b;
    padding: .875rem 2rem
  }
  
  .stamped-review-share {
    display: none
  }
  
  .stamped-pagination .page a {
    padding: .25rem .625rem;
    font-size: 1.125rem;
    font-weight: 500
  }
  
  .stamped-pagination .page.active a {
    font-weight: 700;
    color: #00635b
  }
  
  .accessories-row .shopify-buy-frame {
    margin: 0 10px
  }
  
  @media screen and (max-width: 991px) {
    div[id*="product-component-4col"], .product-accessories.col4 .w-dyn-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      -ms-grid-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px
    }
    .product-dropdown-link {
      padding-top: 0;
    }
  }
  
  @media screen and (max-width: 767px) {
    .product-accessories .shopify-buy__product__title {
      font-size: 1.5rem
    }
  }
  
  @media screen and (max-width: 479px) {
    div[id*="product-component-4col"], .product-accessories.col4 .w-dyn-items {
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr
    }
  }
  
  .product-accessories .shopify-buy__product-img-wrapper::before {
    padding-top: 66.667%
  }
  
  .product-accessories .shopify-buy__product .shopify-stars,
  div[id*="product-component-single"] .shopify-buy__product .shopify-stars {
    font-size: 1rem;
    letter-spacing: 0;
    margin-bottom: 1rem
  }
  
  #product-table-subnav .shopify-buy__product__actual-price, .product-accessories .shopify-buy__product__actual-price {
    font-size: 1.125rem
  }
  
  
  .shopify-buy__product__actual-price.shopify-buy__price--lowered {
    color: #009677;
    font-weight: 600;
    letter-spacing: -0.5px
  }
  .shopify-buy__product__actual-price.shopify-buy__price--lowered::after {
    content: '*';
  }
  .shopify-buy__product__compare-price {
    color: #111;
    text-decoration: line-through;
    font-size: 1.125rem;
    margin-left: 0.25rem;
  }
  .w-col-3 .shopify-buy__product__compare-price, .w-col-4 .shopify-buy__product__compare-price, .product-col-4 .shopify-buy__product__compare-price {
    opacity: 0;
  }
  .highlight .shopify-buy__product__actual-price.shopify-buy__price--lowered, .highlight .shopify-buy__product__compare-price {
    color: #00635b
  }
  .highlight .shopify-buy__product__variant-selectors + .shopify-buy__btn-wrapper {
    margin-top: 1.25rem;
  }
  
  div[id*="product-component"] .shopify-buy__product {
    border: 1px solid #ddd
  }
  
  .accessories-row__item-stars, .promo__item.addon .stamped-main-badge {
    pointer-events: none;
  }
  
  
  .stamped-main-badge .stamped-badge-caption[data-reviews="0"][data-version="2"]:after {
    content: 'No reviews'!important;
  }
  
  
  .product_buy .shopify-buy__btn::after, div[id*="product-component"] .shopify-buy__btn::after, div[id*="product-table"] .shopify-buy__btn::after, .shopify-buy__modal .shopify-buy__btn::after,.shopify-buy__btn-cta::after {
    content: url(https://uploads-ssl.webflow.com/5cfd83cf1c26abefa3063e12/5d11375e799011dbd4ef008f_baseline-add_shopping_cart-32px-w.svg);
    position: absolute;
    right: .875rem;
    top: .875rem;
    width: 1.75rem;
    height: 1.75rem;
  }
  .part-modal div[id*="product-component"] .shopify-buy__btn::after {
    content: url(https://uploads-ssl.webflow.com/5cfd83cf1c26abefa3063e12/5dee579ce942e7174a4f222f_zoom_in-64px.svg)
  }
  
  div[id*="product-component"] .shopify-buy__product .shopify-buy__btn-wrapper {
    z-index: 5;
    position: absolute;
    right: 0;
    top: 0;
    width: 3.5rem;
    height: 3.5rem;
    overflow: hidden;
    white-space: nowrap;
    transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1)
  }
  
  div[id*="product-component"] .shopify-buy__product .shopify-buy__btn-wrapper:hover {
    width: 11.5rem
  }
  
  
  
  div[id*="product-component"] .shopify-buy__btn-wrapper:hover .shopify-buy__btn {
    color: #fff;
    transition: color 0s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: color .3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  .shopify-buy__product .shopify-buy__btn, div[id*="product-table"] .shopify-buy__btn, .shopify-buy__modal .shopify-buy__btn, .shopify-buy__btn.bundle-btn {
    height: 3.5rem;
    width: 100%;
    min-width: 11.5rem
  }
  
  div[id*="product-component"] .shopify-buy__product .shopify-buy__btn {
    color: transparent;
    padding: 1.25rem 2.25rem 1.25rem 1.4375rem;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: color 0s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transition: color .3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
  }
  
  div[id*="product-component"] .shopify-buy__product__price {
    height: 3.5rem;
    border-top: none;
    width: calc(100% + 2px);
    padding: 1.125rem 1.25rem;
    margin: 0 -1px
  }
  
  .product_buy .shopify-buy__btn, div[id*="product-table"] .shopify-buy__btn, .shopify-buy__modal .shopify-buy__btn, .shopify-buy__btn-cta {
    position: relative;
    padding: 1.25rem 1.5rem;
    width: 11.5rem
  }
  #product-table-subnav .shopify-buy__product {
    display: flex;
    align-items: center
  }
  
  #product-table-subnav .shopify-buy__product__price {
    padding: 1.125rem;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    line-height: 1;
    height: 3.5rem;
    background: #fff;
    color: #00635b;
  }
  #product-table-subnav .shopify-buy__product__actual-price::before {
    content: 'MSRP';
    margin-right: .375rem;
    font-family:"Neue Haas Grotesk Display Pro 65 Md";
    font-size: .625rem;
    letter-spacing: 1px;
    vertical-align: top;
    color: #555;
  }
  
  #product-table-subnav .shopify-buy__btn-cta {
    width: 10rem;
    min-width: 10rem;
  }
  #product-table-subnav .shopify-buy__btn[disabled], .shopify-buy__product .shopify-buy__btn[disabled], div[id*=product-component] .shopify-buy__product .shopify-buy__btn[disabled] {
    padding: .5rem 1.5rem 1.25rem;
  }
  .mj-container .shopify-buy__btn[disabled]::before {
    content: 'Browse Retailers';
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    text-transform: capitalize;
    position: absolute;
    bottom: .75rem;
  }
  .mj-container .shopify-buy__btn[disabled]::before {
    content: 'Browse Retailers';
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    text-transform: capitalize;
    position: absolute;
    bottom: .75rem;
  }
  .shopify-buy__btn[disabled],.shopify-buy__btn-cta[disabled], .mj-container .shopify-buy__btn[disabled] {
    pointer-events: none;
    background-color: #999;
  }
  .part-modal .shopify-buy__product .shopify-buy__btn[instock="false"] {
    background-color:#999
  }
  .disbtn-cta:hover .shopify-buy__btn[disabled],
  .promo__btn:hover .shopify-buy__btn[disabled],
  .part-modal .shopify-buy__product .shopify-buy__btn[instock="false"]:hover  {
    background: #555;
  }
  














  
  
  
  
  
  
  
  
  
  
  














  
  
  
  
  
  
  
  
  
  
  
  /*---3---*/
  
  .blog-item:first-child .blog-item-wrapper, .blog-item:nth-child(6n) .blog-item-wrapper {
    display: block;
    padding: 1.25rem;
    background: none;
    box-shadow: none
  }
  
  .blog-item:first-child .blog-item-wrapper .blog-preview-image, .blog-item:nth-child(6n) .blog-item-wrapper .blog-preview-image {
    width: 100%;
    height: 35vw
  }
  
  .blog-item:first-child .blog-item-wrapper .blog-summary-wrap, .blog-item:nth-child(6n) .blog-item-wrapper .blog-summary-wrap {
    position: relative;
    width: 70%;
    padding: 2.5rem 3.75rem;
    text-align: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 5px 0;
    margin-top: -5rem
  }

  
  .blog-item:first-child .blog-item-wrapper .blog-summary, .blog-item:nth-child(6n) .blog-item-wrapper .blog-summary {
    display: none
  }
  
  .blog-item:first-child .blog-item-wrapper .label.cc-blog-date, .blog-item:nth-child(6n) .blog-item-wrapper .label.cc-blog-date {
    margin-top: 1.25rem
  }
  .blog-content.rich-text p {
    font-size: 1.125rem;
    color: #676767;
  }
  
  .load-bar {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 2px;
    background-color: #ddd
  }
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center
  }
  
  .bar:nth-child(1) {
    background-color: #ddd;
    animation: loading-line 2s linear infinite
  }
  
  .bar:nth-child(2) {
    background-color: #009677;
    animation: loading-line 2s linear 1s infinite
  }
  
  @keyframes loading-line {
    from {
      left: 50%;
      width: 0;
      z-index: 100
    }
  
    33.3333% {
      left: 0;
      width: 100%;
      z-index: 10
    }
  
    to {
      left: 0;
      width: 100%
    }
  }
  
  .hiw__graphic-circle_outer:nth-child(1) {
    animation: pulse-fade 2s linear infinite
  }
  
  .hiw__graphic-circle_outer:nth-child(2) {
    animation: pulse-fade 2s linear 1s infinite
  }
  
  @keyframes pulse-fade {
    from {
      transform: scale(1);
      opacity: 1
    }
  
    to {
      transform: scale(1.5);
      opacity: 0
    }
  }
  .svg-arrow-down {
    pointer-events: none
  }
  
  .product-col-4 .shopify-buy__product-img-wrapper, .product-accessories .shopify-buy__product-img-wrapper {
    margin-bottom: 1rem;
    overflow: hidden;
    padding-bottom: 67%;
    height: 0;
    position: relative
  }
  .shopify-buy__layout-vertical .shopify-buy__product__variant-img {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0
  }
  .shopify-buy__product-img-wrapper::after {
    content: '';
    display: table;
    clear: both
  }
  
  @media screen and (max-width: 1281px) {
    .mj-container {
      min-width: inherit;
      width: 90%
    }
  
    .featured-items, .featured-items__last {
      min-width: inherit;
      width: 100vw
    }
  
    .split-section-half, .section-text {
      min-width: inherit;
      width: 45vw
    }
    .benefit .split-section-image {
      background-size:contain
    }
  }
  @media screen and (min-width: 992px) {
    .product-sub-nav__title-wrap {
      max-width: calc(100% - 680px)
    }
    .product-sub-nav__title {
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 991px) {
    .mj-container {
      width: 100%
    }
  
    .blue-logo.mj-logo {
      opacity: 1 !important
    }
  
    .split-section-half, .section-text {
      width: 100%
    }
  
    .navigation {
      padding: 0
    }
  
    .navigation-wrapper.header-overlay .nav-title {
      color: #111 !important
    }
  
    .w-col {
      width: 100%
    }
  
    .columns.expert-reviews__wrap .w-col {
      width: 100%
    }
  
    .nav-dropdown .nav-title::after, .nav-link .nav-title::after {
      content: none
    }
    .product-sub-nav {
      transform-style: preserve-3d
    }
    .product-sub-nav__links-container::before {
      content: '';
      height: 2px;
      width: 70px;
      background: #aaa;
      position: absolute;
      z-index: 9;
      top: 1.75rem;
      left: 4.5rem
    }
  
    .specs-table {
      overflow-x: scroll
    }
  
    #videos .w-col {
      width: 50%
    }
  
    .product-accessories .shopify-buy__product__title,
    .shopify-buy__modal .shopify-buy__product__title{
      height: auto
    }
  
    .product-sub-nav__link-text {
      white-space: nowrap
    }
  
    .blog-item:first-child .blog-item-wrapper .blog-summary-wrap, .blog-item:nth-child(6n) .blog-item-wrapper .blog-summary-wrap {
      width: 70%;
      padding: 1.25rem
    }
    .highlight::before {
      content: '';
      z-index: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,.6);
    }
  }
  
  @media screen and (max-width: 479px) {
    .product-sub-nav__links-container::before {
      content: none
    }  
    .shopify-buy__product-img-wrapper.shopify-buy__product-img-wrapper {
      max-height: none
    }
  
    .product_buy-wrap .shopify-buy__product-img-wrapper {
      margin-bottom: 1.25rem
    }
  
    #videos .w-col, .w-col.w-col {
      width: 100%
    }
  
    #product-main-feature .shopify-buy__product__title {
      margin: 0 0 1rem
    }
  
    #product-main-feature .shopify-buy__layout-horizontal .shopify-shortdesc, #product-main-feature .shopify-buy__layout-horizontal .shopify-stars, #product-main-feature .shopify-buy__layout-horizontal .shopify-shipping {
      margin-left: 0
    }
  
    blockquote {
      font-size: 1.25rem;
      line-height: 1.5rem
    }
    .highlight .shopify-buy-frame--product, .highlight .highlight-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .clearfix:after {
    display: table;
    clear: both;
    content: ''
  }
  
  .visuallyhidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0
  }
  
  .component-container {
    overflow: hidden
  }
  
  .shopify-buy__type--center {
    text-align: center
  }
  
  .shopify-buy--visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important
  }
  
  .shopify-buy__btn, .shopify-buy__btn-cta {
    display: block;
    font-family:"Neue Haas Grotesk Display Pro 65 Md";
    font-size: .875rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    background-color: #00635b;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    border: 0;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    transition: background .2s ease, border-color .2s ease
  }
  
  .shopify-buy__btn:focus, .shopify-buy__btn:hover, .shopify-buy__btn-cta:focus, .shopify-buy__btn-cta:hover, .mj-container .shopify-buy__btn:hover {
    background-color: #009677;
    color:#fff
  }
  
  .shopify-buy__btn--parent {
    padding: 0;
    cursor: pointer;
    border: 0;
    background-color: transparent
  }
  
  .shopify-buy__btn--parent:focus .product__variant-img, .shopify-buy__btn--parent:hover .product__variant-img {
    opacity: .7
  }
  
  .shopify-buy__btn--cart-tab {
    position: fixed;
    z-index: 2147483647;
    top: 50%;
    right: 0;
    width: auto;
    min-width: inherit;
    height: auto;
    padding: 5px 11px;
    -webkit-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    opacity: 0;
    border-radius: 3px 0 0 3px
  }
  
  .shopify-buy__btn--cart-tab.is-active {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1
  }
  
  .shopify-buy__btn__counter {
    font-size: 18px;
    display: block;
    margin: 0 auto 10px
  }
  
  .shopify-buy__icon-cart--side {
    width: 20px;
    height: 20px
  }
  
  #promo__oos .shopify-buy__btn[disabled] {
    border-color: #777;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .mj-container #promo__oos .shopify-buy__btn[disabled]::before {
    position: static;
    content: 'Browse at participating retailers';
    margin-top: 2px
  }
  .shopify-buy__btn[disabled][data-element="product.button"],
  .shopify-buy__btn-cta[disabled][data-element="product.button"],
  .shopify-buy-frame .disbtn-cta {
    display:none
  }
  .shopify-buy__btn[disabled] + .disbtn-cta, .shopify-buy__btn-cta[disabled] + .disbtn-cta {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  .disbtn-cta a {
    height: 100%;
    width: 100%;
    display: block;
  }
  .shopify-buy__modal .shopify-buy__btn[disabled][data-element="product.button"] {
    display: block;
    padding-top: 1.25rem;
  }
  .mj-container .shopify-buy__btn[disabled]::after, .shopify-buy__modal .shopify-buy__btn[disabled]::after {
    content:url(https://uploads-ssl.webflow.com/5cfd83cf1c26abefa3063e12/5e7b89c4a5e32ae367fa805f_remove_shopping_cart-24px.svg);
  }
  .shopify-buy__btn--close {
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif;
    font-size: 35px;
    position: absolute;
    top: 8px;
    right: 9px;
    padding-right: 9px;
    cursor: pointer;
    transition: transform .1s ease, color .1s ease,
    -webkit-transform .1s ease;
    color: #767676;
    border: 0;
    background-color: transparent
  }
  
  .shopify-buy__btn--close:focus {
    outline: 0
  }
  
  .shopify-buy__btn--close:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: #696969
  }
  
  .shopify-buy__cart-wrapper {
    height: 100%;
    padding-left: 10px
  }
  
  .shopify-buy__cart {
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif;
    font-size: 16px;
    position: absolute;
    right: 0;
    width: calc(100% - 10px);
    height: 100%;
    background-color: #fff;
    box-shadow: -5px 0 5px rgba(0, 0, 0, .1)
  }
  
  .shopify-buy__cart__header {
    position: relative;
    z-index: 2147483647;
    padding: 20px;
    padding-right: 40px;
    background-color: inherit
  }
  
  .shopify-buy__cart__title {
    font-size: 2rem;
    font-weight: 500;
    color: #111;
    overflow: hidden;
    text-overflow: ellipsis
  }
  
  .shopify-buy__cart-scroll {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 110px 0 180px
  }
  
  .shopify-buy__cart-scroll--cart-note {
    padding: 70px 0 200px
  }
  
  .shopify-buy__cart-items {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 400px;
    perspective: 400px;
    -webkit-perspective-origin: 50% 0;
    perspective-origin: 50% 0
  }
  
  .shopify-buy__cart-item {
    position: relative;
    overflow: hidden;
    min-height: 65px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    -webkit-animation: .2s flipIn forwards;
    animation: .2s flipIn forwards;
    -webkit-backface-visibility: visible;
    backface-visibility: visible
  }
  
  .shopify-buy__cart-item.is-hidden {
    -webkit-animation-name: flipOut;
    animation-name: flipOut
  }
  
  .shopify-buy__cart-item__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 65px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain
  }
  
  .shopify-buy__cart-item__title {
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    margin-left: 80px
  }
  
  .shopify-buy__cart-item__price {
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    float: right
  }
  
  .shopify-buy__cart-item__full-price {
    font-size: 14px;
    line-height: 26px;
    margin-left: 8px;
    color: #00635b;
    float: right
  }
  
  .shopify-buy__cart-item__discount, .shopify-buy__cart__discount__text {
    display: none
  }
  .shopify-buy__cart__discount {
    text-align: right;
    color: #df6a26;
    margin-bottom: .25rem;
  }
  
  div[class*="shopify-buy__cart"] {
    font-size: 12px;
    color: #4c4c4c
  }
  
  .shopify-buy__cart-item__variant-title {
    margin-top: 1rem;
    font-weight: 700;
    float: right;
    overflow: hidden;
    max-width: 220px;
    text-overflow: ellipsis;
  }
  
  .shopify-buy__cart-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background-color: #fff
  }
  
  .shopify-buy__cart__subtotal__text {
    font-size: 16px;
    float: left;
    text-transform: uppercase
  }
  
  .shopify-buy__cart__subtotal__price {
    float: right;
    font-size: 1.25rem
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*---4---*/
  .shopify-buy__cart__currency {
    font-size: 12px
  }
  
  .shopify-buy__cart__notice {
    font-size: 12px;
    clear: both;
    padding-top: 10px;
    text-align: center
  }
  
  .shopify-buy__cart__note {
    clear: both;
    padding-top: 10px
  }
  
  .shopify-buy__cart__note__text-area {
    width: 100%;
    resize: none
  }
  
  .shopify-buy__cart-empty-text {
    padding: 10px 15px;
    text-align: center
  }
  
  .shopify-buy__btn--cart-checkout {
    font-size: 1rem;
    letter-spacing: .125rem;
    clear: both;
    width: 100%;
    margin-top: 1rem;
    padding: 1.25rem 1.5rem;
    text-align: center
  }
  
  
  .shopify-buy__quantity-container {
    line-height: 26px;
    margin-left: 80px
  }
  
  .shopify-buy__cart-item__quantity-input {
    float: left;
    background: 0 0
  }
  
  .shopify-buy__quantity-decrement, .shopify-buy__quantity-increment {
    font-family: 'Neue Haas Grotesk Display Pro 65 Md', Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 16px;
    position: relative;
    display: block;
    float: left;
    width: 26px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    text-align: center;
    color: #4c4c4c;
    border: 0;
    border: 1px solid #767676;
    background: 0 0;
    box-shadow: none
  }
  
  .shopify-buy__quantity-decrement svg, .shopify-buy__quantity-increment svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin-top: -6px;
    margin-left: -7px;
    fill: currentColor
  }
  
  .shopify-buy__quantity-decrement {
    border-radius: 3px 0 0 3px
  }
  
  .shopify-buy__quantity-increment {
    border-radius: 0 3px 3px 0
  }
  
  .shopify-buy__quantity {
    font-size: 16px;
    display: inline-block;
    width: 45px;
    height: 30px;
    padding: 0;
    text-align: center;
    color: #000;
    border: 0;
    border-top: 1px solid #767676;
    border-bottom: 1px solid #767676;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield
  }
  
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
  }
  .shopify-buy__quantity-container.shopify-buy__quantity-with-btns {
    overflow: hidden
  }

  .shopify-buy__quantity-container.shopify-buy__quantity-with-btns .shopify-buy__quantity {
    float: left;
    border-right: 0;
    border-left: 0
  }
  
  .shopify-buy__layout-horizontal .shopify-buy__product__title {
    margin-top: 10px
  }
  
  .shopify-buy__layout-vertical .shopify-buy__btn:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn:first-child {
    margin-top: 0
  }
  
  .shopify-buy__layout-vertical .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity {
    margin: 20px auto 0;
    display: flex
  }
  
  .shopify-buy__layout-vertical .shopify-buy__btn-and-quantity:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity:first-child {
    margin: 0 auto
  }
  
  .shopify-buy__layout-vertical .shopify-buy__product__variant-img, .shopify-buy__layout-horizontal .shopify-buy__product__variant-img {
    max-width: 100%
  }
  
  .no-image .shopify-buy__product-img-wrapper {
    display: none
  }
  
  @media screen and (min-width:992px) and (max-width:1280px){
    div[id*="product-component"] .shopify-buy__btn-wrapper:hover .shopify-buy__btn-wrapper {
      width: 3.5rem;
    }
    .dropdown-toggle {
      padding: 2.25rem 1.5rem 2.25rem 0.625rem;
    }
    .nav-title {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  .accessories-row__item .shopify-buy__option-select-wrapper {
    border: none;
  }
  .product_buy .promo .shopify-buy__option-select-wrapper {
    margin-top:0
  }
  .product_buy .promo__notice .shopify-buy__option-select-wrapper {
    margin-top: .5rem;
  }
  .shopify-buy__option-select-wrapper {
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    color: #111;
    background: #fff;
    overflow: hidden;
    vertical-align: bottom;
  }
  .shopify-buy__cart-item__variant-title {
    float: left;
  }
  .shopify-buy__option-select__select {
    font-size: inherit;
    padding: 7px 10px;
    border: 0;
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .shopify-buy__select-icon {
    cursor: pointer;
    display: block;
    fill: #111;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px;
    pointer-events: none;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
  
  
  div[id*=product-component] .shopify-buy__option-select-wrapper {
    border: none;
    box-sizing: border-box;
    position: absolute;
    background: transparent;
    width: calc(100% - 3.5rem);
    top: 0;
  }
  div[id*=product-component] .shopify-buy__option-select__select {
    font-size: 0.875rem;
    margin: 0 0 0 100px;
    height: 3.5rem;
    padding: 10px 10px 7px 25px;
    max-width:calc(100% - 29px)
  }
  
  div[id*=product-component] .shopify-buy__select-icon {
    fill: #009677;
    left: calc(1.25rem + 90px);
    width: 14px;
    height: 14px;
  }
  
  
  
  .flex-container__col.img::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .flex-container__col.img::before, .offset-body.half.img::before, .featured__img-wrap:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 66.667%;
  }
  
  .shopify-buy__carousel {
    font-size: 0;
    text-align: center;
    min-height: 90px;
    margin-left: -15px;
    margin-top: 15px
  }
  
  .shopify-buy__carousel-item {
    width: calc(16.666% - 15px);
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 0;
    border: none
  }
  
  .shopify-buy__carousel-item:nth-child(n+7) {
    margin-top: 15px
  }
  
  .shopify-buy__carousel-item:before {
    content: "";
    display: block;
    padding-top: 100%
  }
  .main-image-wrapper {
    position: relative
  }
  
  .carousel-button {
    position: absolute;
    width: 75px;
    top: 0;
    height: 100%;
    border: none;
    font-size: 0;
    background-color: transparent;
    opacity: .4;
    cursor: pointer
  }
  
  .carousel-button:hover, .carousel-button:focus {
    opacity: .9;
    outline: none
  }
  .carousel-button-arrow {
    width: 20px;
    display: inline-block;
    margin-left: 25px
  }
  .carousel-button--previous {
    left: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  
  .carousel-button--next {
    right: 0
  }
  
  .shopify-buy__carousel-item--selected {
    opacity: .4
  }
  .shopify-buy__product {
    font-size: 16px;
    position: relative
  }
  .shopify-buy__product-img-wrapper img {
    width: 100%;
    height: auto
  }
  
  .shopify-buy__product .shopify-cat {
    font-weight: 500;
    margin: 0 1.25rem
  }
  
  .shopify-buy__product__actual-price {
    font-size: 1.25rem
  }
  
  .shopify-buy__product .shopify-buy__btn-wrapper {
    position: relative;
    z-index: 10
  }
  
  .int-link {
    position: absolute;
    height: calc(100% - 100px);
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1
  }
  
  .shopify-buy__modal .shopify-buy__product {
    padding: 40px
  }
  
  .shopify-buy__modal .carousel-button-arrow {
    width: 1.25rem
  }
  
  .shopify-buy__modal .main-image-wrapper>img {
    width: 80%;
    margin: auto
  }
  
  .shopify-buy__modal .shopify-buy__product-description p {
    line-height: 1.4;
    margin-bottom: 1.25rem
  }
  
  
  
  .product_buy .shopify-buy__quantity-container {
    height: 3.5rem;
  }
  
  .shopify-buy__btn-and-quantity .shopify-buy__quantity {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff
  }
  
  .product_buy .shopify-buy__quantity, .shopify-buy__modal .shopify-buy__quantity {
    display: block;
    border-color: #ddd;
    line-height: 1.2;
    font-size: 1.25rem;
    height: 3.5rem;
    width: 3.5rem;
    padding: 1rem 0;
    border-left: 1px solid #ddd;
    border-radius: 3px 0 0 3px
  }
  
  .shopify-buy__btn-and-quantity .shopify-buy__quantity-container, .shopify-buy__btn-and-quantity .shopify-buy__btn-wrapper {
    margin: 0
  }
  
  .shopify-buy__modal {
    background: #fff;
    width: calc(100% - 20px);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2147483646
  }
  
  .shopify-buy__modal .shopify-buy__product__title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    overflow: inherit;
    height: auto;
  }
  
  .shopify-buy__modal .shopify-buy__product__price {
    color: #00635b
  }
  
  .shopify-buy-frame--modal {
    overflow: scroll
  }
  
  .shopify-buy__modal .shopify-buy__modal-product-wrapper {
    width: 100%
  }
  










  @-webkit-keyframes dash {
    to {
      stroke-dashoffset: 0
    }
  }
  
  @keyframes dash {
    to {
      stroke-dashoffset: 0
    }
  }
  
  @-webkit-keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-200px);
      -webkit-transform: translateY(-200px)
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0)
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-200px);
      -webkit-transform: translateY(-200px)
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0)
    }
  }
  
  @-webkit-keyframes slideOut {
    from {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0)
    }
  
    to {
      opacity: 0;
      transform: translateY(-200px);
      -webkit-transform: translateY(-200px)
    }
  }
  
  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0)
    }
  
    to {
      opacity: 0;
      transform: translateY(-200px);
      -webkit-transform: translateY(-200px)
    }
  }
  
  @-webkit-keyframes flipIn {
    from {
      max-height: 0;
      margin-bottom: -65px;
      -webkit-transform: rotatex(90deg) translatey(-50%);
      transform: rotatex(90deg) translatey(-50%);
      opacity: 0
    }
  
    to {
      max-height: none;
      margin-bottom: 20px;
      -webkit-transform: none;
      transform: none;
      opacity: 1
    }
  }
  
  @keyframes flipIn {
    from {
      max-height: 0;
      margin-bottom: -65px;
      -webkit-transform: rotatex(90deg) translatey(-50%);
      transform: rotatex(90deg) translatey(-50%);
      opacity: 0
    }
  
    to {
      max-height: none;
      margin-bottom: 20px;
      -webkit-transform: none;
      transform: none;
      opacity: 1
    }
  }
  
  @-webkit-keyframes flipOut {
    from {
      max-height: none;
      margin-bottom: 20px;
      -webkit-transform: none;
      transform: none;
      opacity: 1
    }
  
    to {
      max-height: 0;
      margin-bottom: -65px;
      -webkit-transform: rotatex(90deg) translatey(-50%);
      transform: rotatex(90deg) translatey(-50%);
      opacity: 0
    }
  }
  
  @keyframes flipOut {
    from {
      max-height: none;
      margin-bottom: 20px;
      -webkit-transform: none;
      transform: none;
      opacity: 1
    }
  
    to {
      max-height: 0;
      margin-bottom: -65px;
      -webkit-transform: rotatex(90deg) translatey(-50%);
      transform: rotatex(90deg) translatey(-50%);
      opacity: 0
    }
  }
  
  @media (max-width: 499px) {
    .shopify-buy__modal.shopify-buy__modal {
      overflow: scroll;
      position: absolute
    }
  
    body.is-active {
      overflow: hidden;
      position: fixed;
      height: 100vh;
      transition: all 0
    }
  
    .shopify-buy__modal {
      width: 100%;
      min-height: 100vh;
      position: fixed;
      overflow-y: auto
    }
  
    .shopify-buy__product__variant-img {
      max-height: 60vh;
      margin: 0 auto;
      width: auto;
      max-width: 100%
    }
  
    .shopify-buy__btn--close {
      position: fixed;
      top: 16px;
      right: 4px;
      z-index: 1
    }
  }
  
  @media (min-width: 500px) {
    html, body.is-active {
      height: 100%;
      overflow: visible
    }
  
    .shopify-buy__modal-overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      overflow-y: scroll
    }
  
    .shopify-buy__modal {
      margin: 100px auto 40px;
      opacity: 0;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, .72);
      -webkit-transform: translateY(-200px);
      transform: translateY(-200px);
      max-width: 1000px;
      -webkit-animation: 200ms slideOut forwards;
      animation: 200ms slideOut forwards
    }
  
    .is-active .shopify-buy__modal {
      -webkit-animation-name: slideIn;
      animation-name: slideIn
    }
  
    .shopify-buy__modal .shopify-buy__btn--close {
      top: -60px;
      color: #fff
    }
  
    .shopify-buy__modal .shopify-buy__btn--close:hover {
      color: #fff
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) {
      text-align: left;
      margin-bottom: 0;
      margin-left: 0
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper {
      float: left;
      width: 40%
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title {
      text-align: left
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__btn-wrapper, .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__quantity-container, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors {
      margin-left: calc(40% + 25px)
    }
  }
  
  @media (min-width: 680px) {
    .shopify-buy__modal .shopify-buy__product-img-wrapper {
      width: 50%
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper {
      float: left;
      width: 50%
    }
  
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__btn-wrapper, .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__quantity-container, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors {
      margin-left: calc(60% + 25px)
    }
  }
  
  .svg-paper-airplane a { 
    display:block
  }
  .svg-paper-airplane svg {
    position: relative;
    transform: rotate(-30deg) scale(1);
    transition: all 1s cubic-bezier(.165,.84,.44,1) 0s;
    transform-origin: 12rem 5rem;
    top: -5rem
  }
  .svg-paper-airplane:hover svg {
    transform: rotate(60deg) scale(0);
  }


















  
